import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {  Stack } from "@mui/material";
import { Grid, Paper, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useForm } from '../hooks';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CorrispondenteDialog({ 
    isOpen,
    setIsOpen,
    data,
    postDatafunc,
    singleSpedMode,    
    setSingleSpedMode, 
    setlastSped,
    setLastPrg        
}) {

    const Esiti = [
        {
            codice: "C",
            descrizione: "Consegnata"
        },
        {
            codice: "CR",
            descrizione: "Consegnata con riserva"
        },
        {
            codice: "NC",
            descrizione: "Non consegnata"
        },
    ]

    const [dataConsegnata, setDataConsegnata] = useState(null)
    const [esitoConsegna, setEsitoConsegna]   = useState('')


    const [esitiList, setEsitiList] = useState([
        {
          id: uuidv4(),
          PrgInterno: "-1",
          PrefissoNPratica: "-1",
          DataConsegna: "",
          Esito: "",
        },
      ]);


      const {
        values: listaEsiti,
        changeHandler,
        setValues,
      } = useForm(
        {
          esiti: [  ],
        },
        []
      );

    const handelChangeValue = (key, value, index, row) => {
        let arrayData = [...esitiList.filter((res) => res.id !== row.id)];
        arrayData.splice(index, 0, { ...esitiList[index], [key]: value });
        setEsitiList(arrayData);
        changeHandler("esiti", arrayData);
    };

    


    const columns = [
        { field: 'PrefissoNumeroPratica', headerName: 'N° Pratica', width: 150 },
        // {
        //     field: 'Mittente', headerName: 'Mittente', flex: 1, renderCell: (params) => {
        //         return (
        //             <Typography>{params.row.Mittente.replace("<br>", ' ').replace("<br>", ' ')}</Typography>
        //         )
        //     }
        // },
        {
            field: 'Destiatario', headerName: 'Destinatario', flex: 1, renderCell: (params) => {
                return (
                    <Typography>{params.row.Destinatario.replace("<br>", ' ').replace("<br>", ' ')}</Typography>
                )
            }
        },
        { field: '', headerName: 'Data consegna', flex: 1,renderCell: (params) => {
            return (
                <DatePicker
                    label="Data merce pronta"
                    value={""}
                    onChange={(e, newValue) => {
                    //changeHandler("DataPronta", moment(e).format("YYYY-MM-DD"));
                    handelChangeValue()
                    }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                />
            )
        } },
    ];

    const handleAnnullaClick = () => {
        setIsOpen( false );
        setSingleSpedMode( false )
        setDataConsegnata( null );
        setLastPrg( "" )
        setlastSped( "" )
    };


    const handleConfermaClick = () => {
        setIsOpen(false);
        postDatafunc( moment(dataConsegnata).format("YYYY-MM-DD"), esitoConsegna);
        setDataConsegnata(null);
    };


    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={isOpen}
                //onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge       = "start"
                            color      = "inherit"
                            onClick    = {handleAnnullaClick}
                            aria-label = "close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Conferma data di consegna
                        </Typography>

                    </Toolbar>
                </AppBar>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>  
    

                        <Paper
                            elevation={0}
                            sx={{mt:5, mb:15,ml:10, mr:10}}
                        >
                            <Stack direction={'row'} spacing={2}>
                            <DatePicker                            
                                label="Data consegna"
                                value={dataConsegnata || null}
                                onChange={(newValue) => {
                                    setDataConsegnata( newValue );
                                }}
                                renderInput={(params) => (
                                    
                                    <TextField
                                    autoComplete="off"
                                    size="small"
                                    {...params}
                                    />
                                )}
                            />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Esito</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        fullWidth
                                        size="small"
                                        value={ esitoConsegna || "" }
                                        label="Esito"
                                        onChange={(e) =>
                                            setEsitoConsegna(e.target.value)
                                        }
                                    >
                                        {Esiti? (
                                            Esiti.map((servizio, i) => (
                                                <MenuItem key={i} value={servizio.codice}>
                                                    {servizio.descrizione}
                                                </MenuItem>
                                        ))):(<></>)}
                                    </Select>
                                </FormControl>

                            </Stack>



                            <Stack 
                                direction={"row"}
                                spacing={1}
                                sx={{mt: 5,ml:10, mr:10}}
                            >
                                <Button 
                                    // sx      = {{mt:5}}
                                    autoFocus 
                                    onClick = {handleConfermaClick}
                                    variant = {"contained"}
                                    color   = 'success'
                                >
                                    CONFERMA
                                </Button>

                                <Button 
                                    // sx      = {{mt:5}}
                                    autoFocus 
                                    onClick = {handleAnnullaClick}
                                    variant = {"contained"}
                                    color   = 'warning'
                                >
                                    ANNULLA
                                </Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>  
            </Dialog>
        </div>
    );
}