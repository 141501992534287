import * as React from "react";
import {
  ButtonGroup,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { EsistenzeContext } from "../context";
import { useEffect } from "react";
import { EsistenzeFilterBar } from "../components";
import { titlePage } from "../utilities";
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const limit = 100;
const offset = 0;

const Esistenze = () => {
  const { t } = useTranslation("common", { keyPrefix: "spedizioni" });
  const [pageResetVar, setPageResetVar] = useState(false);
  const [NPage, setNPage] = useState(0)
  const [ pageOffset, setPageOffset ] = useState(0);

  const {
    state: {
      esistenzeList,
      recordCount,
      filter,
      columnsCustom
    },
    getEsistenzeList,
    getConfigModeEsistenze,
    aggiornaFiltri,
    resetFiltri,
   } = useContext(EsistenzeContext);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    titlePage("", t("titlePage"));
    //if(!isReminder){
        getEsistenzeList(
        "",
        limit,
        offset,
        navigate,
        [],
        recordCount,
        false,
        filter,
        false,
      );
    //}
    //getConfigModeEsistenze()

    setReload(false);

// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
        <Grid container spacing={2}>
        <Grid container item mr={2} spacing={2} direction={"row"}>
          <Grid item xs={12} md={12} lg={12}>
            <EsistenzeFilterBar
              /*   setOpenTabella={setOpenTabella} */
              type={true}
              recordCount={recordCount}
              filter={filter}
              aggiornaFiltri={aggiornaFiltri}
              getMethod={getEsistenzeList}
              resetFiltri={resetFiltri}
              setViewStatoWeb={true}
              setViewAdvFilter={true}
              setPageResetVar={setPageResetVar}
              pageResetVar={pageResetVar}
              setCounterApi={setPageOffset}
              setPage={setNPage}
            ></EsistenzeFilterBar>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <ButtonGroup size={"large"} orientation={"horizontal"}>
          </ButtonGroup>


          {columnsCustom.length > 0 ? (
            <CustomPaginationActionsTable
              rows={esistenzeList}
              columns={columnsCustom}
              limit={100}
              navigate={navigate}
              getMethodForList={getEsistenzeList}
              recordCount={recordCount}
              //routeDetail={"/spedizioni"}
              idElement={"Articolo"}
              actionShow={false}
              viewAction={[]}
              pageSearchReset={false}
              pageReset={pageResetVar}
              setPageReset={setPageResetVar}
              filterList={filter}
              resettaFiltri={resetFiltri}
              reload={reload}
              counterApi={pageOffset}
              setCounterApi = {setPageOffset}
              page={NPage}
              setPage = {setNPage}
            />
          ) : (
            <Stack justifyContent={"center"} alignContent="center">
              <Typography>Caricamento...</Typography>
              <LinearProgress color="success" />
            </Stack>
          )}
        </Grid>      
      </Grid>
    </div>

  );
};

export default Esistenze;
