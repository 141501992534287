import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import { ConfigClient, settingsMenu, titleAppBar } from "../utilities";
import { AuthContext, ThemeContext } from "../context";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Grow, Menu, Stack, Tooltip, Typography, Paper } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import {
  FiberManualRecordTwoTone,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const drawerWidth = 240;
const settings = settingsMenu;

function MiniDrawer(props) {
  const { t } = useTranslation("common",{ keyPrefix: 'utilities' })
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [pages, setPages] = useState(ConfigClient());
  const [LogoSoc, setLogoSoc] = useState([]);
  const [user, setUser] = useState(localStorage.getItem("user"));
  const [configurazioni, setConfigurazioni] = useState(localStorage.getItem("configurazioni"));

  let location = useLocation();

  const {
    state: { theme },
    darkMode,
  } = React.useContext(ThemeContext);

  const {
    logout,
  } = React.useContext(AuthContext);

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  //24/10/2023 Greg: getMyToolTip implementato per pagina bianca
  //                 tentando di caricare da localstorage user se null.
  //                 Segnalato da transmart.
  //                 Implementato controllo anche su configurazioni.
  const getMyToolTip = () => {
    if (user === null){

      return(
        <Tooltip title={t("Settings")}>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar />
        </IconButton>
      </Tooltip> 
      )
    }
    else{
      return(
        <Tooltip title="Settings">
          <IconButton
            onClick={handleOpenUserMenu}
            size="small"
            sx={{ ml: 2 }}
            //aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            // aria-expanded={open ? "true" : undefined}
          >
            <Paper
              sx={{
                border: 2,
                borderColor: (theme) => theme.palette.primary.light,
                borderRadius: 30,
                backgroundColor: (theme) => theme.palette.info.main,
              }}
            >
              <div translate="no">
                <Typography
                  fontFamily={"unset"}
                  fontSize={15}
                  color={"white"}
                  p={1}
                >
                  {                        
                    user
                    .substring(0, 3)
                    .toUpperCase()
                  }
                </Typography>
              </div>
            </Paper>
          </IconButton>
        </Tooltip>
      )
    }
  }

  React.useEffect(() => {
    if (user === null){
      navigate("/");
    }

    if (configurazioni != null){
      let path = window.location.href
      let splitpath = path.split("/");
      let public_path = splitpath[0] + '//'+splitpath[2] + '/';

      if (JSON.parse(localStorage.getItem("configurazioni")).logoFile512) {
        setLogoSoc(
          public_path + JSON.parse(localStorage.getItem("configurazioni")).logoFile512
        );
      }
    }
    else{
      navigate("/")
    }

    let dataArray = [];
    if (pages) {
      pages.map((data, index) => {
        dataArray = [...dataArray, { ...data, select: false }];
      });
    }
    setPages(dataArray);
  }, []);


  const Change = () => {
    darkMode(!theme);
    if (theme) {
      localStorage.setItem("dark", false);
    } else {
      localStorage.setItem("dark", true);
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting) => {
    if (setting.idMenu === 2) {
      logout(navigate);
    }
    setAnchorElUser(null);
  };

  const drawer = (
    <div>
      <Toolbar />
      <List>

        {pages.map((page, index) =>{
          if (page.menuName !== "") {
            return (
              <div translate="no">
              <ListItemButton
                key={page.menuName}
                onClick={() => navigate(page.route)}
              >
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText primary={t(page.menuName)} />
                &nbsp;
                <Grow
                  in={
                    location.pathname.split("/")[1] === page.route.split("/")[1]
                  }
                  style={{ transformOrigin: "0 0 0" }}
                  {...(location.pathname.split("/")[1] ===
                  page.route.split("/")[1]
                    ? { timeout: 1000 }
                    : {})}
                >
                  <ListItemIcon sx={{pl:5}}>
                  <FiberManualRecordTwoTone
                      color="success"
                      fontSize="small"
                    />
                  </ListItemIcon>
                </Grow>
                
              </ListItemButton>
              </div>
            );
          } else {
            return (
              <ListItemButton
                key={"sitoesterno" + index}
                target="_blank"
                onClick={() =>
                  window.open(page.route, "_blank", "noopener,noreferrer")
                }
              >
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText primary={page.title} />
              </ListItemButton>
            );
          }
        })}
      </List>
    </div>
  );

  return (

    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <div translate="no">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          {
            configurazioni !== null? (
          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
            <Stack direction={"row"} spacing={2}>
              <Grow
                in={
                  JSON.parse(localStorage.getItem("configurazioni"))
                    .logoFile512 && LogoSoc.length > 0
                }
                style={{ transformOrigin: "0 0 0" }}
                {...(LogoSoc.length > 0 ? { timeout: 1000 } : {})}
              >
                <img
                  src={
                    LogoSoc.length > 0
                      ? JSON.parse(localStorage.getItem("configurazioni"))
                          .logoFile512
                      : require("../static/logo_true.png")
                  }
                  alt={"..."}
                  style={{ width: 40, height: 40 }}
                />
              </Grow>
                <div translate="no">
                  <Typography variant="h6" noWrap fontFamily={"Roboto"} >
                    {localStorage.getItem("titleshort")?.toUpperCase()}
                  </Typography>
                </div>
            </Stack>
          </Box>
            ):
            (<></>
            )}
          
          <Typography
            variant="h6"
            noWrap
            sx={{ flexGrow: 1 }}
            fontFamily={"Roboto"}
          >
            {titleAppBar(location.pathname, props?.titoloPassato, t).toUpperCase()}
          </Typography>

         {
          getMyToolTip()
         }
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }} 
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <ListItemButton  color="inherit">
              <div translate="no">
                <Typography>                
                  {user || ""}
                </Typography>
              </div>
            </ListItemButton>
            <ListItemButton onClick={() => Change()} color="inherit">
              <ListItemIcon>
                {theme ? <Brightness4Icon /> : <Brightness7Icon />}
              </ListItemIcon>
              <ListItemText primary={theme ? "Light Mode" : "Dark Mode"} />
            </ListItemButton>
            {/* <LanguagesComponent/> */}
            {settings.map((setting) => (
              <ListItemButton
                key={setting.menuName}
                onClick={() => handleCloseUserMenu(setting)}
                color="inherit"
              >
                <ListItemIcon>{setting.icon}</ListItemIcon>
                <ListItemText primary={t(setting.menuName)} />
              </ListItemButton>
            ))}
          </Menu>
          


        </Toolbar>
        </div>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          //container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: {
            xs: `calc(100% - ${drawerWidth}px)`,
            sm: `calc(100% - ${drawerWidth}px)`,
          },
          height: "100%",
        }}
        /*  key={uuidv4()} */
      >
        <Toolbar />
        {props.child}
      </Box>
    </Box>
  );
}

MiniDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MiniDrawer;
