import {
  CancelTwoTone,
  Download,
  EditTwoTone,
  EmailTwoTone,
  LocationOn,
  PrintTwoTone,
  VisibilityTwoTone,
} from "@mui/icons-material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  List,
  Avatar,
  Dialog,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ActionButton = ({
  routeDetail,
  data,
  idElement,
  viewAction,
  configPrint,
  deleteAction,
  emailAction,
  uploadPODAction,
  confermaConsegnaSingolaAction
}) => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (codicedoc) => {
    let path = localStorage.getItem("path");
    let splitPrm = path.split("/");
    window.open(
      `${splitPrm[0]}${routeDetail}/print/${data[idElement]}/${codicedoc}`,
      "_blank",
      "noopener,noreferrer"
    );
    setOpen(false);
  };

  const VisibilityTwoToneOpenNewPage = () => {
    //navigate(`${routeDetail}/${data[idElement]}`)
    //11/04/2024 Greg: Per marco cassol: Apertura pagine dettaglio in nuova scheda.
    //                 Necessità dettata da perdita posizione di pagina tabella tornando indietro
    let path = localStorage.getItem("path");
    let splitPrm = path.split("/");
    window.open(
      `${splitPrm[0]}${routeDetail}/${data[idElement]}`,
      "_blank",
      "noopener,noreferrer"
    );

  };

  const editFunzioni =()=>{
    navigate(`${routeDetail}/edit/${data[idElement]}`)
  }

  return (
    <Stack direction={"row"}>
      {viewAction.view ? (
        <IconButton
          color="primary"
          size="small"
          onClick={() =>VisibilityTwoToneOpenNewPage()}
        >
          <VisibilityTwoTone />
        </IconButton>
      ) : null}
      {viewAction.print ? (
        <>
          <IconButton
            color="info"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <PrintTwoTone />
          </IconButton>
          <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Scelta stampa</DialogTitle>
            <List sx={{ pt: 0 }}>
              {configPrint.map((print) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(print.codicedoc)}
                  key={print.codicedoc}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: red[400] }} size="small">
                      <PrintTwoTone color="inherit"  size="small"/>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={print.descrizione} />
                </ListItem>
              ))}
            </List>
          </Dialog>
        </>
      ) : null}
      {viewAction.edit ? (
        <IconButton color="success" size="small" onClick={() => editFunzioni()}>
          <EditTwoTone  />
        </IconButton>
      ) : null}
      {viewAction.mail ? (
        <IconButton color="warning" size="small" onClick={()=> emailAction(data[idElement])}>
          <EmailTwoTone  />
        </IconButton>
      ) : null}
      {viewAction.location ? (
        <IconButton color="primary" size="small" onClick={() => routeDetail(data)}>
          <LocationOn />
        </IconButton>
      ) : null}
      {viewAction.download ? (
        <IconButton
          color="primary"
          size="small"
          onClick={() => navigate(`${routeDetail}/${data[idElement]}`)}
        >
          <Download />
        </IconButton>
      ) : null}
      {viewAction.delete ? (
        <IconButton color="error" size="small" onClick={() => deleteAction(data[idElement])}>
          <CancelTwoTone  />
        </IconButton>
      ) : null}
      {viewAction.uploadPOD ? (
        <IconButton color="primary" size="small" onClick={() => uploadPODAction(data)}>
          <UploadFileIcon  />          
        </IconButton>
        
      ) : null}
      {viewAction.confermaConsegnaSingola ? (
        <IconButton color="primary" size="small" onClick={() => confermaConsegnaSingolaAction(data)}>
          <CheckCircleIcon  />          
        </IconButton>
        
      ) : null}
    </Stack>
  );
};

export default ActionButton;
