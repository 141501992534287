import createDataContext from "./createContext";
import { WebTrakingService,BaseRedirect } from "../api";
import moment from "moment";
import { sendNotification } from "../hooks/useNotification";
import { NotificationContent } from "../components";
import { sendDialogCustuom } from "../hooks/useDialogCustuom";
import { notificationMessage } from "../hooks/useNotification";


const ESISTENZE_LIST = "tracking_list";
const COLUMNS_CUSTOM = "columns_custom";
const COLUMNS_DETAIL = "columns_detail";
const FILTER = "filter";
const FILTER_RESET = "filter_reset";
const CONFIG_SPED = "config_sped";
const ERROR_MESSAGGE = "error_messagge";

const INITIAL_STATE = {
  
  esistenzeList: [],
  columnsCustom: [],
  configSped: [],
  recordCount: 0,
  filter: {
    searchall      : "",
    CodiceArticolo       : "",
    Lotto          : "",
    RiferimPaletta : "",
    BarCode        : "",
    DescrMerce     : "",
  },
  resettaFiltri : false,
  ricercaFiltri : false,
  azioni: []
};

const esistenzeReducer = (state, action) => {
  switch (action.type) {
    case ESISTENZE_LIST: {
      const { data, countRecord } = action.payload;
      return {
        ...state,
        esistenzeList             : data,
        recordCount               : countRecord,
        resettaFiltri             : false,
      };
    }
    case COLUMNS_CUSTOM: {
      return { ...state, columnsCustom: action.payload };
    }
    case COLUMNS_DETAIL: {
      return { ...state, columnsCustomDetail: action.payload };
    }
    case FILTER: {
      const { key, value } = action.payload;
      return { ...state, filter: { ...state.filter, [key]: value } };
    }
    case FILTER_RESET: {
      return { ...state, filter: INITIAL_STATE.filter, resettaFiltri: true };
    }
    case CONFIG_SPED: {
      return { ...state, configSped: action.payload };
    }
    case ERROR_MESSAGGE: {
      return { ...state, errorMessage2: action.payload };
    }
    default: {
      return state;
    }
  }
};

const getEsistenzeList =
  (dispatch) =>
  async (
    rif,
    limit,
    offset,
    navigate,
    dataSet,
    recordCount,
    reset,
    filterTracking
  ) => {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/magmovim/magesistenza/websearch?limit=${limit}&offset=${offset}&RiferimPaletta=${
        filterTracking ? filterTracking.RiferimPaletta : ""
      }&searchall=${
        filterTracking ? filterTracking.searchall : ""
      }&Articolo=${
        filterTracking ? filterTracking.CodiceArticolo : ""
      }&Lotto=${
        filterTracking ? filterTracking.Lotto : ""
      }&DescrMerce=${
        filterTracking ? filterTracking.DescrMerce : ""
      }&Barcode=${
        filterTracking?.Barcode ? filterTracking.Barcode : ""
      }`
    );
    if (response.data) {

      let data = dataSet;
      if (dataSet.length === 0 || rif !== "") {
        dispatch({
          type: ESISTENZE_LIST,
          payload: {
            data: response.data.data,
            countRecord: response.data.RecordCount,
          },
        });
      } else if (dataSet.length < recordCount) {
        response.data.data.forEach((element) => {
          data.push(element);
        });
        dispatch({
          type: ESISTENZE_LIST,
          payload: {
            data: data,

            /*  ? response.data.data.length
              : response.data.recordcount_total, */
          },
        });
      }
    }
    let col = [];
    response.data.columns.forEach((data) => {
      col = [
        ...col,
        {
          field: data.id,
          headerName: data.label,
          minWidth: data.minWidth,
          //flex: 1,
        },
      ];
    });
    dispatch({ type: COLUMNS_CUSTOM, payload: col });
  };







const aggiornaFiltri = (dispatch) => async (key, value) => {
  dispatch({ type: FILTER, payload: { key: key, value: value } });
};

const resetFiltri = (dispatch) => async () => {
  dispatch({ type: FILTER_RESET });
  getEsistenzeList(dispatch)("", 100, 0, false, [], 0, false, {
    searchall: "",
    DataIniziale: "",
    DataFinale: "",
    Riferimento: "",
    DestProvincia: "",
    DestLocalita: "",
    DestRagSoc: "",
    DestNazione: "",
    StatoWeb: "",
  });
  getEsistenzeList(dispatch)(
    "",
    100,
    0,
    false,
    [],
    0,
    false,
    {
      searchall: "",
      DataIniziale: "",
      DataFinale: "",
      Riferimento: "",
      DestProvincia: "",
      DestLocalita: "",
      DestRagSoc: "",
      DestNazione: "",
      StatoWeb: "",
    },
    false
  );
};




const getConfigModeEsistenze = (dispatch) => async () => {
  console.log("TODO::: getConfigModeEsistenze non implementato");
  // const response = await WebTrakingService.get(
  //   `${
  //     JSON.parse(localStorage.getItem("configurazioni")).server
  //   }/spedizioni/spedizioniweb/config`
  // );
  // if (response) {
  //   dispatch({ type: CONFIG_SPED, payload: response.data.data.spedizioni });
   
  // }

};

export const { Provider, Context } = createDataContext(
    esistenzeReducer,
  {
    getEsistenzeList,
    aggiornaFiltri,
    resetFiltri,
    getConfigModeEsistenze,
  }, // actions
  INITIAL_STATE // initial state
);
