import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import BasicSpeedDial from "./BasicSpeedDial";
import {
  Cancel,
  FilterAltRounded,
  PlayArrow,
  TravelExploreTwoTone,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from "../hooks";
import { useEffect, useContext } from "react";
import { getFlagPath, setTimerSearchSpedizioniWebSearch } from "../utilities";



export default function EsistenzeFilterBar({
  type,
  recordCount,
  filter,
  aggiornaFiltri,
  getMethod,
  resetFiltri,
  setViewAdvFilter,
  setCounterApi,
  setPage,
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");

  const [CodiceArticolo, setCodiceArticolo] = React.useState("");
  const [Lotto, setLotto]                   = React.useState("");
  const [ Barcode, setBarcode ]             = React.useState("");
  const [RiferimPaletta, setRiferimPaletta] = React.useState("");
  const [ DescrMerce, setDescrMerce ]       = React.useState("");

  const [searchall, setSearchall] = React.useState("");

  const [spedizioniListSearchTimeOut, setSpedizioniListSearchTimeOut] = React.useState(null);



  const limit = 100;
  const offset = 0;

  const ChangeValueHandler = (key, value, setFunc) => {
    if (key === "all") {
      resetFiltri();
      setCodiceArticolo("");
      setLotto("");
      setBarcode("");
      setRiferimPaletta("");
      setDescrMerce("");
      setSearchall("");
      setOpen(!open);
    } else {
      setFunc(value);
      aggiornaFiltri(key, value);
      //setPageResetVar(true);
      /* setTimeout(() => , 0.1 * 1000); */
    }
  };

  const ResetPage = () =>{
    if(setCounterApi){
      setCounterApi(0)
      setPage(0)
    }
    else{
      console.log("CHE STRANO NON TROVO setCounterApi:", setCounterApi)
    }
  }

  useEffect(() => {
    ResetPage(false);
    //setCounterApi(0)
  }, []);

  useEffect(() => {
    ResetPage(true);

    if (filter?.searchall === "") {
      //10/11/2024 Greg: in caso desse fastidio anche usando il timer, è possibile commentare seguente
      //per impedire aggioranamento automatico della pagina in digitazione e obbligare l'utente a cerc<are con 'avvia ricercaa'

      handleAvviaRicerca()
    }
  }, [filter]);

  const navigate = useNavigate();

  const PressKey = (e) => {
    if (e === 13) {
      handleAvviaRicerca();
      //getMethod("", limit, offset, navigate, [], recordCount, false, filter);
    }
  };

  const handleAvviaRicerca = () => {

    setTimerSearchSpedizioniWebSearch(
      spedizioniListSearchTimeOut,
      setSpedizioniListSearchTimeOut,
      () => {
        getMethod( "", limit, offset, navigate, [], recordCount, false, filter);
        ResetPage(true);
      }
    );

  };

  const handleAnnullaRicerca = () => {
    ResetPage(true);
    getMethod("", limit, offset, navigate, [], recordCount, false, filter);
  };

  let dimension = useWindowDimensions();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          border: 2,
          borderColor: (theme) => theme.palette.primary.light,
          borderRadius: 20,
          background: (theme) => theme.palette.background.paper,
          color: "black",
        }}
        elevation={0}
      >
        <Toolbar variant="dense">
          {setViewAdvFilter ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={() => setOpen(!open)}
            >
              <Slide in={true}>
                <FilterAltRounded color={"primary"} />
              </Slide>
            </IconButton>
          ) : (
            <></>
          )}

          <TextField
            hiddenLabel
            autoComplete="off"
            size="small"
            fullWidth
            value={searchall || ""}
            onChange={(e) =>
              ChangeValueHandler("searchall", e.target.value, setSearchall)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <Player
                    // src="https://assets8.lottiefiles.com/packages/lf20_nIuZ4T.json"
                    src="cors_json/lf20_nIuZ4T.json"
                    autoplay
                    style={{ height: 45, width: 45 }}
                  /> */}
                  {/* 
                        14/08/2024 Greg: Sostituisco con iconbutton 
                        Segnalato da E.Colcera, il tasto "search" companiva andando su BasicSpeedDial,
                        e ingannava perchè non effettuata alcuna ricerca, sostituito con iconbutton nello startAdornment
                        sostituito con freccia vs destra nel BasicSpeedDial
                  
                  */}
                  <IconButton onClick={() =>{handleAvviaRicerca()} }>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {filter?.searchall !== "" ? <IconButton color="primary" aria-label="upload picture" component="label" onClick={() => ChangeValueHandler("searchall", "", setSearchall)}>
                    <Cancel />
                  </IconButton> : <></>}
                </>
              )


            }}
            variant="standard"
            placeholder={"Cerca ..."}
            onKeyPress={(e) => PressKey(e.charCode)}
          />

          {dimension ? <></> : <BasicSpeedDial  />}

        </Toolbar>
      </AppBar>
      <Stack direction={"row"} spacing={2}>
        {dimension ? <BasicSpeedDial /> : <></>}
      </Stack>
      <Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mt={1}>

              {/* { 
               filtriAttivi?.destinatario?.visible ? */}
                  <Card elevation={0}>


                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6} lg={6}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          size="small"
                          label="Codice articolo"
                          variant="outlined"
                          value={CodiceArticolo || ""}
                          onChange={(e) => {
                            ChangeValueHandler(
                              "CodiceArticolo",
                              e.target.value,
                              setCodiceArticolo
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Riferimento paletta"
                          variant="outlined"
                          autoComplete="off"
                          value={RiferimPaletta || ""}
                          onChange={(e) => {
                            ChangeValueHandler(
                              "RiferimPaletta",
                              e.target.value,
                              setRiferimPaletta
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <TextField
                          fullWidth
                          autoComplete="off"
                          size="small"
                          label="Barcode"
                          variant="outlined"
                          value={Barcode || ""}
                          onChange={(e) => {
                            ChangeValueHandler(
                             "Barcode",
                              e.target.value,
                              setBarcode
                            );
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <TextField
                          fullWidth
                          autoComplete="off"
                          size="small"
                          label="Descrizione merce"
                          variant="outlined"
                          value={DescrMerce || ""}
                          onChange={(e) => {
                            ChangeValueHandler(
                             "DescrMerce",
                              e.target.value,
                              setDescrMerce
                            );
                          }}
                        />
                      </Grid>                      

                      <Grid item xs={12} md={6} lg={6}>
                        <TextField
                          fullWidth
                          autoComplete="off"
                          size="small"
                          label="Lotto"
                          variant="outlined"
                          value={Lotto || ""}
                          onChange={(e) => {
                            ChangeValueHandler(
                             "Lotto",
                              e.target.value,
                              setLotto
                            );
                          }}
                        />
                      </Grid>            
                    </Grid>
                  </CardContent>
                </Card>

       


              
              <Stack direction={"row"} spacing={2} mt={1}>
                <Button
                  variant="contained"
                  size="small"
                  color={"primary"}
                  endIcon={<PlayArrow />}
                  onClick={() => handleAvviaRicerca()}
                >
                  Avvia ricerca
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color={"warning"}
                  endIcon={<Cancel />}
                  onClick={() => ChangeValueHandler("all")}
                >
                  Resetta filtri
                </Button>
              </Stack>
            </Box>
          </Collapse>

      </Box>
    </Box>
  );
}
