import * as React from "react";
import { 
  Grid, 
  LinearProgress, 
  Stack, 
  Typography,
  Button,
  ButtonGroup,
} from "@mui/material";
import {
  LocalShipping,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { TrackingContext } from "../context";
import { useEffect } from "react";
import { CorrispondenteDialog, FilterBar, UploadDialog } from "../components";
import { titlePage } from "../utilities";
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const limit = 100;
const offset = 0;

const listaStato =[
  { value:"CON", text:"Consegnato"},
  { value:"INC", text:" In consegna"},
  { value:"CON", text:"Disitinta"},
  { value:"ALTRI", text:"In lavorazione"},
]


const Corrispondente = () => {
  const { t }                                               = useTranslation("common",{ keyPrefix: 'Corrispondente' })
  const [ pageResetVar, setPageResetVar ]                   = useState( false );
  const [ checkBoxSelection, setCheckBoxSelection ]         = useState( []    );
  const [ isOpen, setIsOpen ]                               = useState( false );
  const [ NPage, setNPage ]                                 = useState( 0     );
  const [ pageOffset, setPageOffset ]                       = useState( 0     );
  const [ uploadPodDialogOpen, setUploadPodDialogOpen ]    = useState( false );
  const [ lastSpedSelected, setlastSpedSelected ]           = useState( ""    );
  const [ lastPrgSelected, setlastPrgSelected ]             = useState( ""    );
  //05/02/2024 Greg: per capire se sto inserendo spedizione singola.
  const [ singleSpedMode, setSingleSpedMode ]               = useState( false );

  const navigate = useNavigate();

  const {
    state: { 
      trackingList, 
      columnsCustom, 
      recordCount, 
      filter, 
      resettaFiltri, 
      azioni,
      confermaConsegnaActive
    },
    getCorrispondenteList,
    aggiornaFiltri,
    resetFiltriCorrisp,
    trackEmail,
    postConsegnato,
    postDataConsegna,
    postUploadPod
  } = useContext( TrackingContext );


  const [reload, setReload] = useState( false );


  useEffect(() => {
    titlePage("", t("titlePage"));
    getCorrispondenteList(
      "",
      limit,
      offset,
      navigate,
      [],
      recordCount,
      false,
      filter
    );
  }, []);

  const emailActionTrak=(e)=>{
    trackEmail(e)
   }

  const uploadPODAction = (e) => {
    setlastSpedSelected( e?.PrefissoNumeroPratica );
    setlastPrgSelected ( e?.PrgInterno            );

    setUploadPodDialogOpen(true);

  } 


  const confermaConsegnaSingolaAction = (e) => {
    setlastSpedSelected( e?.PrefissoNumeroPratica );
    setlastPrgSelected ( e?.PrgInterno            );
    setIsOpen(true)
    setSingleSpedMode(true);
  } 

  
  const uploadFilePOD = (fileBase64) => {
        // console.log( "TODO:::: Implementare Corrispondente.uploadFilePOD" );
        // console.log( "fileBase64 ", fileBase64 );
        // console.log( "lastPrgSelected", lastPrgSelected );
        postUploadPod( fileBase64, lastPrgSelected);
  } 


  const handlePostConfermaConsegna = (dataConsegna, esito) => {

    let prgSpedList = '';

    if ( singleSpedMode !== true){
      checkBoxSelection.forEach((element) => {
        prgSpedList = prgSpedList  + element.PrgInterno + ',';
      });
    }
    else {
      prgSpedList = lastPrgSelected 
    };

    postDataConsegna( dataConsegna, esito, prgSpedList );

    setlastSpedSelected("");
    setlastPrgSelected ("");
  } 


  return (
    <>
      <Grid container spacing={2}>
        <Grid container item mr={2} spacing={2} direction={"row"}>
          <Grid item xs={12} md={12} lg={12}>
            <FilterBar
              type             = {true}
              recordCount      = {recordCount}
              filter           = {filter}
              aggiornaFiltri   = {aggiornaFiltri}
              getMethod        = {getCorrispondenteList}
              resetFiltri      = { resetFiltriCorrisp }
              setViewStatoWeb  = {true}
              setViewAdvFilter = {true}
              setPageResetVar  = {setPageResetVar}
              listStatoWeb     = {listaStato}
              setCounterApi    = {setPageOffset}
              setPage          = {setNPage}
            ></FilterBar>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>

        <ButtonGroup size={"large"} orientation={"horizontal"}>
          {confermaConsegnaActive && confermaConsegnaActive === true ? (
            <Button
              variant   = "text"
              startIcon = {<LocalShipping />}
              disabled  = {checkBoxSelection.length < 1}
              onClick   = {() =>
                setIsOpen(true)
              }
            >
              { t("ConfermaConsegnato") }
            </Button>
          ):(<></>)}
            <CorrispondenteDialog 
              isOpen            = { isOpen }
              setIsOpen         = { setIsOpen }
              data              = { checkBoxSelection }
              postDatafunc      = { handlePostConfermaConsegna }
              singleSpedMode    = { singleSpedMode }
              setSingleSpedMode = { setSingleSpedMode }
              setlastSped       = { setlastSpedSelected }
              setLastPrg        = { setlastPrgSelected }
            />
          </ButtonGroup>

          {columnsCustom.length > 0 ? (            
            <CustomPaginationActionsTable
              setCheckBoxSelectionPrint = {setCheckBoxSelection} 
              rows             = { trackingList }
              columns          = { columnsCustom }
              limit            = { 100 }
              navigate         = { navigate }
              getMethodForList = { getCorrispondenteList }
              recordCount      = { recordCount }
              routeDetail      = { "/tracking" }
              idElement        = { "PrgInterno" }
              pageReset        = { pageResetVar }
              actionShow       = { true }
              viewAction = {{
                mail                    : false,
                view                    : true,
                location                : false,
                print                   : false,
                edit                    : false,
                delete                  : false,
                uploadPOD               : true,
                confermaConsegnaSingola : confermaConsegnaActive 
              }}
              /*emailAction={(e)=>emailActionTrak(e)}*/
              filterList                 = { filter }
              resettaFiltri              = { resettaFiltri }
              counterApi                 = { pageOffset }
              setCounterApi              = { setPageOffset }
              page                       = { NPage }
              setPage                    = { setNPage }
              uploadPODAction            = { uploadPODAction }
              confermaConsegnaSingolaAct = {confermaConsegnaSingolaAction}
            />
          ) : (
            <Stack justifyContent={ "center" } alignContent="center">
              <Typography>{t("loadPage")}</Typography>
              <LinearProgress color="success" />
            </Stack>
          )}
        </Grid>
      </Grid>

      <UploadDialog
        open             = { uploadPodDialogOpen }
        setOpen          = { setUploadPodDialogOpen }
        //testDoc={testDoc}
        postFile         = { uploadFilePOD }
        dialogTitle      = { "Caricamento POD" }
        numRif           = { lastSpedSelected }  
        // ultimare scritture lastspedselected in uplad dialog    
        mode             = { "POD" }     
      />
    </>
  );
};

export default Corrispondente;
